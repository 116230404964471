<template>
  <template v-if="refIsVisible">
    <el-dialog v-model="refIsVisible"
    :title="getI18n('警告')"
    :width="800"
    :draggable="true"
    :close-on-click-modal="false"
    >
      <el-table :data="refData" border style="width: 100%">
        <el-table-column prop="warning_type" :label="getI18n('类型')" width="80" />
        <el-table-column prop="content" :label="getI18n('内容')"/>
        <el-table-column prop="created_at" :label="getI18n('产生时间')"  width="200" />
      </el-table>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="onOk()">{{getI18n('关闭')}}</el-button>
        </div>
      </template>
    </el-dialog>
  </template>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted } from 'vue'
import { apiFetchData, getI18n, isEmptyArray } from '../utils'

export default defineComponent({
  name: 'WarningDlg',
  components: {
  }
})
</script>

<script lang="ts" setup>
const refData = ref([])
const refIsVisible = ref(false)

const queryWarning = async () => {
  const data = await apiFetchData('/action?__action_id=pl_query_warning')
  refData.value = data.data
  refIsVisible.value = !isEmptyArray(refData.value)
  // console.log('refIsVisible.value=', refIsVisible.value, Date.now() / 1000)
}
onMounted(async () => {
  console.log('WarningDlg enter')
  queryWarning()

  setInterval(() => {
    queryWarning()
  }, 1000 * 60 * 10)
})
function onOk () {
  refData.value = []
  refIsVisible.value = false
}
</script>

<style>
.el-dialog {
  display: flex !important;
  flex-direction: column !important;
  margin-top: 5% !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
</style>
