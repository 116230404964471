<template>
  <el-select
    v-model="refValue"
    filterable
    :allow-create="props.allowCreate"
    :multiple="props.multiple"
    :filter-method="customFilter"
    placeholder="Select"
    style="width: 100%;"
    clearable
>
    <el-option
    v-for="item in refOptions"
    :key="item.value"
    :label="item.label"
    :value="item.value"
    />
  </el-select>
</template>

<script lang="ts">
import { defineComponent, ref, defineProps, onMounted } from 'vue'
import { apiFetchData } from '../utils'

export default defineComponent({
  name: 'DropDownSelector'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  dataValue: {
    type: String,
    require: true
  },
  multiple: {
    type: Boolean,
    require: false,
    default: false
  },
  allowCreate: {
    type: Boolean,
    require: false,
    default: false
  },
  srcDataUrl: {
    type: String,
    require: true
  }
})

const refValue = ref('')
const refOptions = ref([])
let optionsCopy = []

// mounted时
onMounted(async () => {
  console.log('onMounted props.dataValue=', props.dataValue)
  console.log('onMounted props.srcDataUrl=', props.srcDataUrl)
  const data = await apiFetchData(props.srcDataUrl)
  optionsCopy = data.data
  refOptions.value = optionsCopy
  refValue.value = props.dataValue
})

const customFilter = (query :string) => {
  console.log('query=', query)
  console.log('refValue=', refValue.value)
  if (query !== '') { // val存在
    // 使用空格将字符串拆分成数组
    const arr = query.trim().split(' ')
    console.log(arr)
    refOptions.value = optionsCopy.filter((item) => {
      for (let i = 0; i < arr.length; i++) {
        const currentValue = arr[i].toLowerCase()
        if (currentValue === '') {
          continue
        }
        // 有不包括的元素
        if (item.label.toLowerCase().indexOf(currentValue.toLowerCase()) < 0) {
          return false
        }
      }
      return true
    })
  } else { // val为空时，还原数组
    refOptions.value = optionsCopy
  }
}
</script>
