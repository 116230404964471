<template>
  <div>
    <el-divider />
    <div class="home" @dblclick="onDblClick">
      <el-text class="mx-1 full-width-text"
        v-for="line in (showContent()).split('\n')"
        :key="line">{{ line }}</el-text>
    </div>
    <el-divider />
    <template v-if="refIsEdit && refIsAdmin">
      <div class="mb-4 full-width-text">
        <el-text class="mx-1 full-width-text">中文描述</el-text>
        <el-input v-model="refZhContent" :autosize="{ minRows: 2, maxRows: 10 }" type="textarea" placeholder="Please input" />
      </div>
      <div class="mb-4 full-width-text" style="margin-top:5px">
        <el-text class="mx-1 full-width-text">英文描述</el-text>
        <el-input v-model="refEnContent" :autosize="{ minRows: 2, maxRows: 10 }" type="textarea" placeholder="Please input" />
      </div>
      <div class="mb-4 full-width-text" style="text-align: right!important;" >
        <el-button type="primary" @click="save">保存</el-button>
        <el-button type="primary" @click="close">关闭</el-button>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, defineProps, onMounted } from 'vue'
import { getLanguage, apiFetchData, apiPostData, isEmptyString, isEmptyArray } from '../utils'
import { getDescriptionUrl, saveSescriptionUrl } from '../config'
import store from '../store'

// 解决vs中的语法错误提示
export default defineComponent({
  name: 'PageDescription'
})
</script>

<script lang="ts" setup>
const refIsEdit = ref(false)
const refZhContent = ref('')
const refEnContent = ref('')
const refLanguage = ref('zh')

const props = defineProps({
  id: {
    type: String,
    require: true
  }
})

const refIsAdmin = ref(false)
onMounted(async () => {
  refIsAdmin.value = store.state.isAdmin
  console.log('refIsAdmin', refIsAdmin.value)
  refLanguage.value = getLanguage()
  const url = `${getDescriptionUrl}&contentKey=${props.id}`
  const data = await apiFetchData(url)

  if (isEmptyArray(data.data) || isEmptyString(data.data[0].zh_content)) {
    refZhContent.value = '请输入说明内容，管理员双击编辑'
  } else {
    refZhContent.value = data.data[0].zh_content
  }
  if (isEmptyArray(data.data) || isEmptyString(data.data[0].en_content)) {
    refEnContent.value = 'please input description. administrator double click to edit'
  } else {
    refEnContent.value = data.data[0].en_content
  }
})

const showContent = () => {
  if (refLanguage.value === 'zh') {
    return refZhContent.value
  } else {
    if ((refEnContent.value.startsWith('please input') || isEmptyString(refEnContent.value)) && !refZhContent.value.startsWith('请输入')) {
      return refZhContent.value
    } else {
      return refEnContent.value
    }
  }
}
const onDblClick = () => {
  refIsEdit.value = !refIsEdit.value
}
const save = () => {
  // refIsEdit.value = false
  const url = `${saveSescriptionUrl}`
  console.log('save')
  const data = { contentKey: props.id, zhContent: refZhContent.value, enContent: refEnContent.value }
  apiPostData(url, data)
    .then(data => {
      if (isEmptyArray(data)) {
        console.log('deleteDatsavea data=', data)
      }
    })
}
const close = () => {
  save()
  refIsEdit.value = false
}
</script>

<style scoped>
.full-width-text {
  display: block;
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left; /* 左对齐 */
  margin-top: 20px !important;
  margin: 5px;
  font-size: 16px;
  color: #606266; /* 确保颜色格式正确 */
  font-weight: normal; /* 确保字体不加粗 */
}
</style>
