<template>
  <el-form label-width="auto" style="max-width: 600px">
    <el-form-item :label="getI18n('账户')">
      <el-text class="mx-1">{{store.state.userAccount}}</el-text>
    </el-form-item>
    <el-form-item :label="getI18n('旧密码')">
      <el-input v-model="refOldPassword" type="password" />
    </el-form-item>
    <el-form-item :label="getI18n('新密码')">
      <el-input v-model="refNewPassword" type="password" />
    </el-form-item>
    <el-form-item :label="getI18n('确认密码')">
      <el-input v-model="refConfirmPassword" type="password" />
    </el-form-item>
    <el-form-item>
      <div class="right-align full-width">
        <el-button type="primary" @click="onSubmit">{{getI18n('修改')}}</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import store from '../store'
import { defineComponent, ref } from 'vue'
import { apiPostData, showRequestResult, getI18n } from '../utils'

// 解决vs中的语法错误提示
export default defineComponent({
  name: 'ChangePasswordPage'
})
</script>

<script lang="ts" setup>
const refOldPassword = ref('')
const refNewPassword = ref('')
const refConfirmPassword = ref('')
const onSubmit = () => {
  const postData = { oldPassWord: refOldPassword.value, newPassword: refNewPassword.value, confirmPassword: refConfirmPassword.value }
  console.log('postData=', postData)
  apiPostData('/action?__action_id=__update_password', postData)
    .then(data => {
      showRequestResult(data, '修改', '修改', '', true, true, true)
    })
}
</script>

<style scoped>
.full-width {
  width: 100%;
}
.right-align {
  display: flex;
  justify-content: flex-end; /* 使内容元素右对齐 */
}
</style>
