<template>
  <el-date-picker
    v-model="refValue"
    :type="props.pickerType"
    placeholder="Pick a day"
    size="default"
    :value-format="props.valueFormat"
  />
</template>

<script lang="ts">
import { isEmptyString } from '@/utils'
import { defineComponent, ref, defineProps, onMounted } from 'vue'

export default defineComponent({
  name: 'DatePicker'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  dataValue: {
    type: String,
    require: true
  },
  pickerType: {
    type: String,
    require: true
  },
  valueFormat: {
    type: String,
    require: true
  }
})

const refValue = ref('')
const refValueFormat = ref('')
onMounted(async () => {
  refValue.value = props.dataValue
  if (isEmptyString(props.valueFormat)) {
    if (props.pickerType === 'date') {
      refValueFormat.value = 'YYYY-MM-DD'
    } else if (props.pickerType === 'month') {
      refValueFormat.value = 'YYYY-MM'
    } else if (props.pickerType === 'month') {
      refValueFormat.value = 'YYYY'
    }
  }
})

</script>
