export const cookieMaxAge = 7 * 24 * 60 * 60

export const rootImagePath = window.location.protocol + '//www.newtechlife.net/images'

export const loginPath = '/login'

export const rootEnglishPath = '/?language=en'
export const rootChinesePath = '/?language=zh'

// 测试环境连服务器环境时，需要指定域名参数，否则cookie 不能成功设置
export let apiLoginPath = '/action?__action_id=__login&__domain=localhost:8080'
if (process.env.NODE_ENV !== 'development') {
  apiLoginPath = '/action?__action_id=__login'
}

export const apiMenuPath = '/action?__action_id=__get_menu'
export const apiSaveMenuPath = '/action?__action_id=__save_menu'

// export const apiReadMePath = '/action?__action_id=__get_page&__page_key=readme.json'
export const apiReadMePath = '/action?__action_id=__get_page&__page_key=15152.json'
export const apiSavePagePath = '__save_page'
export const apiToolComponentPath = '/action?__action_id=__get_components'
export const apiGetPageJsonPath = '/action?__action_id=__get_page&__page_key='

// 数据转发
export const apiForwardPath = '/action?__action_id=__forward_request'

// 通用接口
export const apiPgSelectPath = '/action?__action_id=__pg_select'
export const apiPgInsertPath = '/action?__action_id=__pg_insert'
export const apiPgDeletePath = '/action?__action_id=__pg_delete'
export const apiPgUpdatePath = '/action?__action_id=__pg_update'
export const apiPgExportPath = '/action?__action_id=__pg_export'

export const apiAdminLoadRolePath = '/action?__action_id=__admin_load_role_v7'
export const apiAdminSaveRoleMenusPath = '/action?__action_id=__admin_save_role_menus'

export const apiGetI18nPath = '/action?__action_id=__i18n'

export const getDescriptionUrl = '/action?__action_id=low_query_page_desc'
export const saveSescriptionUrl = '/action?__action_id=low_save_page_desc'

export const emptyPage = { children: [], draggableStyle: 'min-height:120px;' }
