<template>
  <draggable :sort="false" :list="itemList" :group="{name: 'itxst',pull:'clone', put: false }" animation="300" :move="onMove" :clone="cloneDog"  item-key="id" class="el-row">
     <template #item="{ element }">
        <el-col :span="12">
          <el-image :="element" :key="element.id" />
        </el-col>
    </template>
  </draggable>
</template>

<script lang="ts">
import draggable from 'vuedraggable'
import { defineProps, defineComponent } from 'vue'
import { DraggableEvent, isEmptyArray, generateIdByTimestamp } from '../../utils'

// 解决vs中的语法错误提示
export default defineComponent({
  name: 'ComponentList'
})
</script>

<script lang="ts" setup>
interface ItemObject {
  item: object
}
defineProps({
  itemList: {
    type: Object,
    required: true
  }
})

const onMove = (e: DraggableEvent) => {
  if (e.from === e.to) return false
  return true
}
const cloneDog = (item: ItemObject) => {
  const newItem = JSON.parse(JSON.stringify(item.item))
  // newItem.id = String(++store.state.curItemId)
  newItem.id = generateIdByTimestamp()
  if (!isEmptyArray(newItem.attrs)) {
    for (let i = 0; i < newItem.length; i++) {
      // newItem.attrs[i].id = String(++store.state.curItemId)
      newItem.attrs[i].id = generateIdByTimestamp()
    }
  }
  return newItem
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
